import { i18n } from '../utils/i18n.js';
import { createRouter, createWebHistory } from 'vue-router'
import { getPageTitle } from "../utils/PageTitle.js";
import HomeView from '../views/HomeView.vue'

let lang = i18n.global.locale.value;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [ 
    {
      path: '/',
      redirect: `/${lang}`
    },
    {
      path: '/:lang',
      name: 'home',
      component: HomeView,
      meta: { 
        title: "home"
      }
    },
    {
      path: '/:lang/invoice',
      name: 'invoice',
      component: () => import("@/views/InvoiceView.vue"),
      meta: { 
        title: "invoice"
      }
    },
    {
      path: '/:lang/company',
      name: 'company',
      component: () => import("@/views/CompanyView.vue"),
      meta: { 
        title: "company"
      }
    },
    {
      path: '/:lang/functionalities',
      name: 'functionalities',
      component: () => import("@/views/FunctionalitiesView.vue"),
      meta: { 
        title: "functionalities"
      }
    },
    {
      path: '/:lang/appointments',
      name: 'appointments',
      component: () => import("@/views/AppointmentsViews.vue"),
      meta: { 
        title: "appointments"
      }
    },
    {
      path: '/:lang/receptions',
      name: 'receptions',
      component: () => import("@/views/ReceptionsView.vue"),
      meta: { 
        title: "receptions"
      }
    },
    {
      path: '/:lang/reparations',
      name: 'reparations',
      component: () => import("@/views/ReparationsView.vue"),
      meta: { 
        title: "reparations"
      }
    },
    {
      path: '/:lang/planner',
      name: 'planner',
      component: () => import("@/views/PlannerView.vue"),
      meta: { 
        title: "planner"
      }
    },
    {
      path: '/:lang/crm',
      name: 'crm',
      component: () => import("@/views/CrmView.vue"),
      meta: { 
        title: "crm"
      }
    },
    {
      path: '/:lang/invoice',
      name: 'invoice',
      component: () => import("@/views/InvoiceView.vue"),
      meta: { 
        title: "invoice"
      }
    },
    {
      path: '/:lang/data',
      name: 'data',
      component: () => import("@/views/DataView.vue"),
      meta: { 
        title: "data"
      }
    },
    {
      path: '/:lang/mobility',
      name: 'mobility',
      component: () => import("@/views/MobilityView.vue"),
      meta: { 
        title: "mobility"
      }
    },
    {
      path: '/:lang/gdpr',
      name: 'gdpr',
      component: () => import("@/views/GdprView.vue"),
      meta: { 
        title: "gdpr"
      }
    },
    {
      path: '/:lang/success',
      name: 'success',
      component: () => import("@/views/SuccessView.vue"),
      meta: { 
        title: "success"
      }
    },
    {
      path: '/:lang/partners',
      name: 'partners',
      component: () => import("@/views/PartnersView.vue"),
      meta: { 
        title: "partners"
      }
    },
    {
      path: '/:lang/contact',
      name: 'contact',
      component: () => import("@/views/ContactView.vue"),
      meta: { 
        title: "contact"
      }
    },
    {
      path: '/:lang/legal',
      name: 'legal',
      component: () => import("@/views/LegalAdviceView.vue"),
      meta: { 
        title: "legal"
      }
    },
    {
      path: '/:lang/privacy-security',
      name: 'privacySecurity',
      component: () => import("@/views/PrivacySecurityView.vue"),
      meta: { 
        title: "security_policy"
      }
    },
    {
      path: '/:lang/privacy',
      name: 'privacy',
      component: () => import("@/views/PoliticaPrivacidadView.vue"),
      meta: { 
        title: "privacy"
      }
    },
    {
      path: '/:lang/cookies',
      name: 'cookies',
      component: () => import("@/views/CookiesView.vue"),
      meta: { 
        title: "cookies"
      }
    },
    {
      path: '/:lang/digital_invoice',
      name: 'digital_invoice',
      component: () => import("@/views/DigitalInvoiceView.vue"),
      meta: { 
        title: "digital_invoice"
      }
    },
    {
      path: '/:lang/distributor',
      name: 'distributor',
      component: () => import("@/views/DistributorView.vue"),
      meta: { 
        title: "distributor"
      }
    },
    {
      path: '/:lang/cases',
      name: 'cases',
      component: () => import("@/views/CasesView.vue"),
      meta: { 
        title: "cases"
      }
    },
    {
      path: '/:lang/roi',
      name: 'roi',
      component: () => import("@/views/RoiView.vue"),
      meta: { 
        title: "roi"
      }
    },
    {
      path: '/:lang/digital_kit',
      name: 'digital_kit',
      component: () => import("@/views/DigitalKitView.vue"),
      meta: { 
        title: "digital_kit"
      }
    },
    {
      path: '/:lang/consulting_kit',
      name: 'consulting_kit',
      component: () => import("@/views/ConsultingKitView.vue"),
      meta: { 
        title: "consulting_kit"
      }
    },
    {
      path: "/:lang/:pathMatch(.*)*",
      name: "BadPage",
      component: () => import("@/views/BadPageView.vue"),
      meta: { 
        title: "bad_page"
      }
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  window.document.title = getPageTitle(to.meta.title);
  const locale = to.params.lang;

  if (!['es', 'en', 'fr', 'ca', 'pt'].includes(locale)) {
    var path = '/'+(to.fullPath.split('/').filter((v) => (v != "" && v != locale))).join('/');
    if (['mx','py','cl'].includes(locale)) {
      return next('es'+path);
    }
    if (locale == 'br') {
      return next('pt'+path);
    }
    return next('en'+path);
  }

  i18n.global.locale.value = locale;
  return next();
})

export default router
