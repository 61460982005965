import { createI18n } from 'vue-i18n';
import messages from '@intlify/vite-plugin-vue-i18n/messages';

let defaultLocale = navigator.language;
const availableLocales = ['es','en','fr', 'ca', 'pt'];

if((navigator.language == 'es') || (navigator.language == 'es-ES')) {
  defaultLocale = 'es';
}
if((navigator.language == 'ca') || (navigator.language == 'ca-ES')) {
  defaultLocale = 'ca';
}
if((navigator.language == 'pt') || (navigator.language == 'pt-PT')) {
  defaultLocale = 'pt';
}
if((navigator.language == 'fr') || (navigator.language == 'fr-FR')) {
  defaultLocale = 'fr';
}
if((navigator.language == 'en') || (navigator.language == 'en-GB') || (navigator.language == 'en-US')) {
  defaultLocale = 'en';
}


if(!availableLocales.includes(defaultLocale)) {
  defaultLocale = 'en';
}

let locale = defaultLocale

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale,
  fallbackLocale: 'en',
  availableLocales,
  messages,
  fallbackWarn: false,
  missingWarn: false
});