<script setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const i18n = useI18n();
const router = useRouter();

const emit = defineEmits(['language-changed']);

const localeArray = {
    es: 'Español',
    en: 'English',
    fr: 'Français',
    ca: 'Català',
    pt: 'Português',
};

let locale = ref(i18n.locale)

function switchLocale(loc) {
    router.replace({ params: { lang: loc.value } });
    emit('language-changed', locale.value);
}

onMounted(() => {
    if(locale.value == '' || !(i18n.availableLocales).includes(locale.value)) {
        locale.value = 'es'
    }
});
</script>

<template>
    <div class="custom-selector">
        <select v-model="locale" @change="switchLocale(i18n.locale)">
            <option
                v-for="(locale, i) in $i18n.availableLocales"
                :key="`locale-${i}`"
                :value="locale"
                :selected="locale === $i18n.locale"
            >
                {{ localeArray[locale] }}
            </option>
        </select>
        <font-awesome-icon icon="chevron-down" class="chevron-down"/>
    </div>
</template>

<style lang="scss" scoped>
    select {
        border: none;
        color: #666666;
        padding: 2px 10px;
        font-size: 12px;
        width: 70px;
        z-index: 9;
        background-color: transparent !important;
    }
    select option {
        background: rgba(0, 0, 0, .75) ;
        color: #fff;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    }
    .chevron-down{
        font-size: 10px;
        position: absolute;
        right: 10px;
        top: 8px;
    }

    .custom-selector::before {
        display: none;
    }
    .custom-selector::after {
        display: none;
    }

    .custom-selector .chevron-down {
    transition: transform 0.3s ease;
    cursor: pointer;
    }

    .custom-selector:hover .chevron-down {
        animation: bounce 1s ease-in-out infinite;
    }

    @keyframes bounce {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-5px); 
        }
    }
</style>