<script setup>
    import { ref } from 'vue'
    const homePotencialBody = ref(null)
</script>
<template>
    <section class="w-100 wrapper-home-potential">
        <div class="wrapper-home-potential-wrapper">
            <div class="wrapper-header">
                <div class="wrapper-content">
                    <div class="title">
                        {{ $t('home_suite_digital.title') }}
                    </div>
                    <div class="subtitle">
                        {{ $t('home_suite_digital.subtitle') }}
                    </div>
                </div>
                
            </div>
            <div class="wrapper-body show-desktop-mode-flex" ref="homePotencialBody">
                <div class="home-potential-mobile-text">
                    <div class="content-potential-container">
                        <p class="content-potential-container-text">+</p>
                        <ul class="content-potential-container-list">
                            <li class="content-potential-container-item">{{ $t('home_suite_digital.eficience') }}</li>
                            <li class="content-potential-container-item">{{ $t('home_suite_digital.interacion') }}</li>
                            <li class="content-potential-container-item">{{ $t('home_suite_digital.productivity') }}</li>
                            <li class="content-potential-container-item">{{ $t('home_suite_digital.sales') }}</li>
                        </ul>
                    </div>
                </div>
                <div class="column left-column show-desktop-mode-flex">
                    <div class="col-wrapper">
                        <div class="wrapper-block"  
                            data-aos="fade"
                            data-aos-easing="linear"
                            data-aos-duration="3500"
                            data-aos-delay="400">
                            <div class="title">
                                {{ $t('home_suite_digital.eficience') }} +
                            </div>
                            <div class="subtitle">
                                {{ $t('home_suite_digital.eficience_sub') }}
                            </div>
                        </div>
                        <div class="wrapper-block"
                            data-aos="fade"
                            data-aos-easing="linear"
                            data-aos-duration="3500"
                            data-aos-delay="600">
                            <div class="title">
                                {{ $t('home_suite_digital.interacion') }} +
                            </div>
                            <div class="subtitle">
                                {{ $t('home_suite_digital.interacion_sub') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column center-column">
                    <div class="lights light-on"
                        data-aos="fade"
                        data-aos-easing="linear"
                        data-aos-duration="4000">
                        <video
                            class="video-js"
                            id="myVideoPotential"
                            width="550"
                            height="600"
                            preload="auto"
                            autoplay muted>
                            <source src="/assets/es/home/Bombeta.mp4" type="video/mp4">
                        </video>
                    </div>
                </div>
                <div class="column right-column show-desktop-mode-flex">
                    <div class="col-wrapper">
                        <div class="wrapper-block"
                            data-aos="fade"
                            data-aos-easing="linear"
                            data-aos-duration="3500"
                            data-aos-delay="800">
                            <div class="title">
                                + {{ $t('home_suite_digital.productivity') }}
                            </div>
                            <div class="subtitle">
                                {{ $t('home_suite_digital.productivity_sub') }}
                            </div>
                        </div>
                        <div class="wrapper-block"
                            data-aos="fade"
                            data-aos-easing="linear"
                            data-aos-duration="3500"
                            data-aos-delay="1000">
                            <div class="title">
                                + {{ $t('home_suite_digital.sales') }}
                            </div>
                            <div class="subtitle">
                                {{ $t('home_suite_digital.sales_sub') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    .wrapper-home-potential {
        background-color: black;
        text-align: center;
        .wrapper-header {
            display: flex;
            justify-content: center;
            color: white;
            .wrapper-content {
                max-width: 100%;
                padding-top: 61px;
                .title {
                    font-size: 40px;
                    font-family: 'CircularStd-Bold';
                    letter-spacing: -2px;
                }
                .subtitle {
                    font-size: 20px;
                } 
            }
        }
        .wrapper-body {
            display: flex;
            justify-content: center;
            height: 616px;
            color: white;
            img {
                height: 100%;
                width: auto;
            }
            .left-column {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: right;
                padding-top: 62px;
                .col-wrapper {
                    position: relative;
                    min-width: 280px;
                    .wrapper-block:first-child {
                        padding-bottom: 60px;
                    }
                }
            }
            .right-column{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: left;
                padding-top: 62px;
                .col-wrapper {
                    .wrapper-block:first-child {
                        padding-bottom: 60px;
                    }
                }
            }
            .center-column {
                width: 445px;
                position: relative;
                .lights {
                    height: 100%;
                    position: relative;
                }
                video {
                    width: 100%;
                    height: 560px;
                    object-fit: cover;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
            .title {
                font-size: 30px;
                font-family: 'CircularStd-Bold';
            }
            .subtitle {
                font-size: 17px;
            }
        }
        .home-potential-mobile-text {
            display: none;
        }
    }

    @media (max-width: 1000px) {
        .wrapper-home-potential {
            padding: 60px 20px 10px;
            height: auto;
            .wrapper-header .wrapper-content .title {
                font-size: 1.7rem;
            }
            .wrapper-header .wrapper-content .subtitle {
                font-size: 1.3rem;
            }
            .wrapper-body .left-column, 
            .wrapper-body .right-column {
                display: none;
            }
            .home-potential {
                padding: 10px 20px;
            }
            .wrapper-body .center-column{
                height: 355px;
            }
            .wrapper-body .center-column video {
                height: 350px;
                top: 15px;
                bottom: 50px;
            }
            .home-potential-mobile-text {
                display: block;
                padding-top: 30px;
                font-size: 1.5rem;
            }
            .wrapper-body {
                display: block;
                height: auto;
            }
            .wrapper-body .center-column,
            .wrapper-body {
                width: auto;
            }
        }
    }
    @media (max-width: 600px) {
        .wrapper-home-potential .wrapper-header .wrapper-content .title {
            letter-spacing: -1px;
        }
    }
    .home-potential-mobile-text:before{
        content: '';
        float: left;
        position: absolute;
        left: 40%;
        animation: opacity;
        animation-iteration-count: infinite;
        animation-duration: 1s;
    }
    .home-potential-mobile-text:after{
        content: '';
        float: left;
        top: 0px;
        animation: opacity;
        animation-iteration-count: infinite;
        animation-duration: 1s;
        position: absolute;
        left: 55%;
    }
    .content-potential-container {
        position: relative;
        height: 1.7rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }
    .content-potential-container-text {
        position: absolute;
        left: 25%;
        margin-top: 0px;
    }
    .content-potential-container-list {
        position: absolute;
        list-style-type: none;
        animation-name: trans;
        animation-duration: 20s;
        animation-iteration-count: infinite;
        margin-top: 0px;
        text-align: left;
    }
   
    @keyframes opacity{
        0%,50%{opacity:0;color:#1D1F20;}
    }
    @keyframes trans {
        0%, 14.66%{transform:translateY(0%)}
        16.66%, 31.32% {transform:translateY(-25%)}
        33.32%,47.98% {transform:translateY(-50%)}
        49.98%,64.64% {transform:translateY(-75%)}
        66.64%,81.30% {transform:translateY(-50%)}
        83.30%,97.96% {transform:translateY(-25%)}
    }
</style>