<script setup>
    import { ref, onMounted, watch } from 'vue'
    import CustomCarouselItem from '@/components/commons/customCarousel/CustomCarouselItem.vue';
    import 'vue3-carousel/dist/carousel.css';
    import '@/assets/custom_carousel.css';
    import { Carousel, Slide, Pagination } from 'vue3-carousel'
    import { updateBreakpoints, useMq } from "vue3-mq";
    import { useI18n } from 'vue-i18n';

    const { locale } = useI18n();
    let location = ref(locale.value);

    const mq = useMq();
    onMounted(() => {
        updateBreakpoints({
            breakpoints: {
                mobile: 600,
                desktop: 1000
            }
        })
    });

    watch(locale, () => {
        location = locale.value;
    });
</script>
<template>
    <section class="home_suite container">
        <div class="container-wrapper home-block-slider-desktop" v-if="mq.desktop">
            <div class="row block-row"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1100">
                <div class="col-md-4">
                    <router-link :to="`/${location}/appointments`">
                        <div class="home-block-wrapper">
                            <div class="block-header-wrapper">
                                <div class="block-header">
                                    <div class="block-title">
                                        <div class="primary-text car-text">
                                            {{ $t('field.car') }} 
                                        </div>
                                        <div class="text-lowercase">
                                            {{ $t('field.appointments') }}
                                        </div>
                                    </div>
                                    <div class="block-subtitle">
                                        {{ $t('home_digitalize.car_appointment') }}
                                    </div>
                                </div>
                            </div>
                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/appointments.jpg)'}">
                                <span class="fa-layers fa-fw">
                                    <font-awesome-icon icon="fa-solid fa-circle"  style="color:black"/>
                                    <font-awesome-icon icon="fa-solid fa-plus" style="color:white"/>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4">
                    <router-link :to="`/${location}/receptions`">
                        <div class="home-block-wrapper">
                            <div class="block-header-wrapper">
                                <div class="block-header">
                                    <div class="block-title">
                                        <div class="primary-text car-text">
                                            {{ $t('field.car') }} 
                                        </div>
                                        <div class="text-lowercase">
                                            {{ $t('field.reception') }}
                                        </div>
                                    </div>
                                    <div class="block-subtitle">
                                        {{ $t('home_digitalize.car_reception') }}
                                    </div>
                                </div>
                            </div>
                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/reception.jpg)'}">
                                <span class="fa-layers fa-fw">
                                    <font-awesome-icon icon="fa-solid fa-circle"  style="color:black"/>
                                    <font-awesome-icon icon="fa-solid fa-plus" style="color:white"/>
                                </span>                               
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4">
                    <router-link :to="`/${location}/reparations`">
                        <div class="home-block-wrapper">
                            <div class="block-header-wrapper">
                                <div class="block-header">
                                    <div class="block-title">
                                        <div class="primary-text car-text">
                                            {{ $t('field.car') }} 
                                        </div>
                                        <div class="text-lowercase">
                                            {{ $t('field.repair') }}
                                        </div>
                                    </div>
                                    <div class="block-subtitle">
                                        {{ $t('home_digitalize.car_repair') }}
                                    </div>
                                </div>
                            </div>
                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/repair.jpg)'}">
                                <span class="fa-layers fa-fw">
                                    <font-awesome-icon icon="fa-solid fa-circle"  style="color:black"/>
                                    <font-awesome-icon icon="fa-solid fa-plus" style="color:white"/>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row block-row"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1100"
                data-aos-delay="50">
                <div class="col-md-4">
                    <router-link :to="`/${location}/planner`">
                        <div class="home-block-wrapper">
                            <div class="block-header-wrapper">
                                <div class="block-header">
                                    <div class="block-title">
                                        <div class="primary-text car-text">
                                            {{ $t('field.car') }} 
                                        </div>
                                        <div class="text-lowercase">
                                            {{ $t('field.planner') }}
                                        </div>
                                    </div>
                                    <div class="block-subtitle">
                                        {{ $t('home_digitalize.car_planner') }}
                                    </div>
                                </div>
                            </div>
                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/planner.jpg)'}">
                                <span class="fa-layers fa-fw">
                                    <font-awesome-icon icon="fa-solid fa-circle"  style="color:black"/>
                                    <font-awesome-icon icon="fa-solid fa-plus" style="color:white"/>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4">
                    <router-link :to="`/${location}/invoice`">
                        <div class="home-block-wrapper">
                            <div class="block-header-wrapper">
                                <div class="block-header">
                                    <div class="new-tag">
                                        {{ $t('field.novelty') }}
                                    </div>
                                    <div class="block-title">
                                        <div class="primary-text car-text">
                                            {{ $t('field.car') }} 
                                        </div>
                                        <div class="text-lowercase">
                                            {{ $t('field.invoice') }}
                                        </div>
                                    </div>
                                    <div class="block-subtitle">
                                        {{ $t('home_digitalize.car_invoice') }}
                                    </div>
                                </div>
                            </div>
                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/invoice.jpg)'}">
                                <span class="fa-layers fa-fw">
                                    <font-awesome-icon icon="fa-solid fa-circle"  style="color:black"/>
                                    <font-awesome-icon icon="fa-solid fa-plus" style="color:white"/>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4">
                    <router-link :to="`/${location}/crm`">
                        <div class="home-block-wrapper">
                            <div class="block-header-wrapper">
                                <div class="block-header">
                                    <div class="block-title">
                                        <div class="primary-text car-text">
                                            {{ $t('field.car') }} 
                                        </div>
                                        <div class="text-lowercase">
                                            {{ $t('field.customer') }}
                                        </div>
                                    </div>
                                    <div class="block-subtitle">
                                        {{ $t('home_digitalize.car_customer') }}
                                    </div>
                                </div>
                            </div>
                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/crm_img.png)'}">
                                <span class="fa-layers fa-fw">
                                    <font-awesome-icon icon="fa-solid fa-circle"  style="color:black"/>
                                    <font-awesome-icon icon="fa-solid fa-plus" style="color:white"/>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="row block-row"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1100"
                data-aos-delay="100">
                <div class="col-md-4">
                    <router-link :to="`/${location}/data`">
                        <div class="home-block-wrapper">
                            <div class="block-header-wrapper">
                                <div class="block-header">
                                    <div class="block-title">
                                        <div class="primary-text car-text">
                                            {{ $t('field.car') }} 
                                        </div>
                                        <div class="text-lowercase">
                                            {{ $t('field.data') }}
                                        </div>
                                    </div>
                                    <div class="block-subtitle">
                                        {{ $t('home_digitalize.car_data') }}
                                    </div>
                                </div>
                            </div>
                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/data.jpg)'}">
                                <span class="fa-layers fa-fw">
                                    <font-awesome-icon icon="fa-solid fa-circle"  style="color:black"/>
                                    <font-awesome-icon icon="fa-solid fa-plus" style="color:white"/>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4">
                    <router-link :to="`/${location}/mobility`">
                        <div class="home-block-wrapper">
                            <div class="block-header-wrapper">
                                <div class="block-header">
                                    <div class="block-title">
                                        <div class="primary-text car-text">
                                            {{ $t('field.car') }} 
                                        </div>
                                        <div class="text-lowercase">
                                            {{ $t('field.mobility') }}
                                        </div>
                                    </div>
                                    <div class="block-subtitle">
                                        {{ $t('home_digitalize.car_mobility') }}
                                    </div>
                                </div>
                            </div>
                            <div class="block-body"  :style="{ backgroundImage: 'url(assets/es/home/digitalize/mobility.jpg)'}">
                                <span class="fa-layers fa-fw">
                                    <font-awesome-icon icon="fa-solid fa-circle"  style="color:black"/>
                                    <font-awesome-icon icon="fa-solid fa-plus" style="color:white"/>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-md-4">
                    <router-link :to="`/${location}/gdpr`">
                        <div class="home-block-wrapper">
                            <div class="block-header-wrapper">
                                <div class="block-header">
                                    <div class="block-title">
                                        <div class="primary-text car-text">
                                            {{ $t('field.car') }} 
                                        </div>
                                        <div class="text-lowercase">
                                            {{ $t('field.rgdp') }}
                                        </div>
                                    </div>
                                    <div class="block-subtitle">
                                        {{ $t('home_digitalize.car_gdpr') }}
                                    </div>
                                </div>
                            </div>
                            <div class="block-body"  :style="{ backgroundImage: 'url(assets/es/home/digitalize/customer.jpg)'}">
                                <span class="fa-layers fa-fw">
                                    <font-awesome-icon icon="fa-solid fa-circle"  style="color:black"/>
                                    <font-awesome-icon icon="fa-solid fa-plus" style="color:white"/>
                                </span>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="block-more-info"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="500">
                <router-link :to="`/${location}/functionalities`">
                    {{ $t('field.more_info') }}
                    <font-awesome-icon icon="fa-solid fa-circle-plus" />
                </router-link>
            </div>
        </div>
        <div class="home-block-slider-mobile" v-else>
            <div class="custom-carousel-wrapper">
                <div class="custom-carousel-inner-wrapper home-block-carousel">
                    <carousel :items-to-show="1" :wrap-around="true" :mouse-drag="true" :touch-drag="true" :autoplay="4000">
                        <slide :key="1">
                            <router-link :to="`/${location}/appointments`">
                                <CustomCarouselItem>
                                    <div class="w-100">
                                        <div class="home-block-wrapper">
                                            <div class="block-header-wrapper">
                                                <div class="block-header">
                                                    <div class="block-inner-header">
                                                        <div class="block-title">
                                                            <div class="primary-text car-text">
                                                                {{ $t('field.car') }} 
                                                            </div>
                                                            <div class="text-lowercase">
                                                                {{ $t('field.appointments') }}
                                                            </div>
                                                        </div>
                                                        <div class="block-subtitle">
                                                            {{ $t('home_digitalize.car_appointment') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/appointments.jpg)'}">
                                            </div>
                                        </div>
                                    </div>
                                </CustomCarouselItem>
                            </router-link>
                        </slide>
                        <slide :key="2">
                            <router-link :to="`/${location}/receptions`">
                                <CustomCarouselItem>
                                    <div class="w-100">
                                        <div class="home-block-wrapper">
                                            <div class="block-header-wrapper">
                                                <div class="block-header">
                                                    <div class="block-inner-header">
                                                        <div class="block-title">
                                                            <div class="primary-text car-text">
                                                                {{ $t('field.car') }} 
                                                            </div>
                                                            <div class="text-lowercase">
                                                                {{ $t('field.reception') }}
                                                            </div>
                                                        </div>
                                                        <div class="block-subtitle">
                                                            {{ $t('home_digitalize.car_reception') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/reception.jpg)'}">                          
                                            </div>
                                        </div>
                                    </div>
                                </CustomCarouselItem>
                            </router-link>
                        </slide>
                        <slide :key="3">
                            <router-link :to="`/${location}/reparations`">
                                <CustomCarouselItem>
                                    <div class="w-100">
                                        <div class="w-100">
                                            <div class="home-block-wrapper">
                                                <div class="block-header-wrapper">
                                                    <div class="block-header">
                                                        <div class="block-inner-header">
                                                            <div class="block-title">
                                                                <div class="primary-text car-text">
                                                                    {{ $t('field.car') }} 
                                                                </div>
                                                                <div class="text-lowercase">
                                                                    {{ $t('field.repair') }}
                                                                </div>
                                                            </div>
                                                            <div class="block-subtitle">
                                                                {{ $t('home_digitalize.car_repair') }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/repair.jpg)'}">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CustomCarouselItem>
                            </router-link>
                        </slide>
                        <slide :key="4">
                            <router-link :to="`/${location}/planner`">
                                <CustomCarouselItem>
                                    <div class="w-100">
                                        <div class="home-block-wrapper">
                                            <div class="block-header-wrapper">
                                                <div class="block-header">
                                                    <div class="block-inner-header">
                                                        <div class="block-title">
                                                            <div class="primary-text car-text">
                                                                {{ $t('field.car') }} 
                                                            </div>
                                                            <div class="text-lowercase">
                                                                {{ $t('field.planner') }}
                                                            </div>
                                                        </div>
                                                        <div class="block-subtitle">
                                                            {{ $t('home_digitalize.car_planner') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/planner.jpg)'}">
                                            </div>
                                        </div>
                                    </div>
                                </CustomCarouselItem>
                            </router-link>
                        </slide>
                        <slide :key="5">
                            <router-link :to="`/${location}/appointments`">
                                <CustomCarouselItem>
                                    <div class="w-100">
                                        <div class="home-block-wrapper">
                                            <div class="block-header-wrapper">
                                                <div class="block-header">
                                                    <div class="block-inner-header">
                                                        <div class="block-title">
                                                            <div class="primary-text car-text">
                                                                {{ $t('field.car') }} 
                                                            </div>
                                                            <div class="text-lowercase">
                                                                {{ $t('field.invoice') }}
                                                            </div>
                                                        </div>
                                                        <div class="block-subtitle">
                                                            {{ $t('home_digitalize.car_invoice') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/invoice.jpg)'}">
                                            </div>
                                        </div>
                                    </div>
                                </CustomCarouselItem>
                            </router-link>
                        </slide>
                        <slide :key="6">
                            <router-link :to="`/${location}/crm`">
                                <CustomCarouselItem>
                                    <div class="w-100">
                                        <div class="home-block-wrapper">
                                            <div class="block-header-wrapper">
                                                <div class="block-header">
                                                    <div class="block-inner-header">
                                                        <div class="block-title">
                                                            <div class="primary-text car-text">
                                                                {{ $t('field.car') }} 
                                                            </div>
                                                            <div class="text-lowercase">
                                                                {{ $t('field.customer') }}
                                                            </div>
                                                        </div>
                                                        <div class="block-subtitle">
                                                            {{ $t('home_digitalize.car_customer') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/crm_img.png)'}">
                                            </div>
                                        </div>
                                    </div>
                                </CustomCarouselItem>
                            </router-link>
                        </slide>
                        <slide :key="7">
                            <router-link :to="`/${location}/data`">
                                <CustomCarouselItem>
                                    <div class="w-100">
                                        <div class="home-block-wrapper">
                                            <div class="block-header-wrapper">
                                                <div class="block-header">
                                                    <div class="block-inner-header">
                                                        <div class="block-title">
                                                            <div class="primary-text car-text">
                                                                {{ $t('field.car') }} 
                                                            </div>
                                                            <div class="text-lowercase">
                                                                {{ $t('field.data') }}
                                                            </div>
                                                        </div>
                                                        <div class="block-subtitle">
                                                            {{ $t('home_digitalize.car_data') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/data.jpg)'}">
                                            </div>
                                        </div>
                                    </div>
                                </CustomCarouselItem>
                            </router-link>
                        </slide>
                        <slide :key="8">
                            <router-link :to="`/${location}/mobility`">
                                <CustomCarouselItem>
                                    <div class="w-100">
                                        <div class="home-block-wrapper">
                                            <div class="block-header-wrapper">
                                                <div class="block-header">
                                                    <div class="block-inner-header">
                                                        <div class="block-title">
                                                            <div class="primary-text car-text">
                                                                {{ $t('field.car') }} 
                                                            </div>
                                                            <div class="text-lowercase">
                                                                {{ $t('field.mobility') }}
                                                            </div>
                                                        </div>
                                                        <div class="block-subtitle">
                                                            {{ $t('home_digitalize.car_mobility') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/mobility.jpg)'}">
                                            </div>
                                        </div>
                                    </div>
                                </CustomCarouselItem>
                            </router-link>
                        </slide>
                        <slide :key="9">
                            <router-link :to="`/${location}/gdpr`">
                                <CustomCarouselItem>
                                    <div class="w-100">
                                        <div class="home-block-wrapper">
                                            <div class="block-header-wrapper">
                                                <div class="block-header">
                                                    <div class="block-inner-header">
                                                        <div class="block-title">
                                                            <div class="primary-text car-text">
                                                                {{ $t('field.car') }} 
                                                            </div>
                                                            <div class="text-lowercase">
                                                                {{ $t('field.rgdp') }}
                                                            </div>
                                                        </div>
                                                        <div class="block-subtitle">
                                                            {{ $t('home_digitalize.car_gdpr') }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="block-body" :style="{ backgroundImage: 'url(assets/es/home/digitalize/customer.jpg)'}">
                                            </div>
                                        </div>
                                    </div>
                                </CustomCarouselItem>
                            </router-link>
                        </slide>
                        <template #addons>
                            <pagination />
                        </template>
                    </carousel>
                </div>        
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    .car-text {
        padding-right: 5px;
    }
    .block-row {
        padding-bottom: 50px;
    }
    .container-wrapper:hover .home-block-wrapper{
        opacity: 0.6;
    }
    .container-wrapper .home-block-wrapper:hover{
        opacity: 1;
    }
    .container-wrapper .home-block-wrapper:hover .block-body span .fa-circle {
    color: #0047ba !important; 
    }
    
    .home-block-wrapper{
        background-color: rgb(240, 240, 240);
        border-radius: 15px;
        transition: opacity 0.3s ease;
        .block-header-wrapper {
            display: flex;
            align-items: start;
            justify-content: center;
            height: 150px;
        }
        
        .block-header {
            padding: 45px 33px 15px;
            width: 100%;
            position: relative;
            .new-tag {
                position: absolute;
                top: -0px;
                right: 35px;
                background-color: #0047ba;
                color: white;
                font-size: 13px;
                padding: 5px 10px;
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;
            }
            .block-title {
                width: 100%;
                display: flex;
                padding-bottom: 5px;
                font-family: 'CircularStd-Black';
                div {
                    font-weight: bold;
                    font-size: 19px;
                }
            }
            .block-subtitle {
                width: 100%;
                font-size: 15px;
                div {
                    font-weight: bold;
                }
            }
        }
        .block-body {
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            width: 100%;
            min-height: 226px;
            position: relative;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
            img {
                width: 100%;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
            span {
                border: none;
                color: black;
                position: absolute;
                bottom: 10px;
                right: 14px;
                cursor: pointer;
                .fa-circle {
                    font-size: 24px;
                }
                .fa-plus {
                    font-size: 16px;
                    padding-left: 4px;
                }
                &:hover {
                    color: #0047ba;
                    .fa-circle {
                        color: #0047ba !important;
                    }
                }
            }
        }
        
    }
    .block-more-info {
        width: 100%;
        text-align: center;
        padding-bottom: 125px;
        a {
            color: black;
            text-decoration: none;
            font-size: 24px;
            font-weight: bold;
            .fa-circle-plus:hover {
                color: #0047ba;
            }
        }
    }
    .home-block-slider-desktop {
        display: block;
        a {
            text-decoration: none;
            color: #212529;
        }
    }
    .home-block-slider-mobile{
        display: none;
        a {
            text-decoration: none;
            color: #212529;
        }
    }
    .custom-carousel-wrapper {
        position: relative;
        .custom-carousel-inner-wrapper {
            width: 100%;
        }
    }

    @media (max-width: 1000px) {
        .home-block-slider-desktop {
            display: none;
        }
        .home-block-slider-mobile{
            display: block;
            padding-bottom: 60px;
        }
    
        .home-block-wrapper .block-header {
            padding: 0px;
            .block-inner-header {
                padding: 30px 20px;
            }
        }
        .home-block-wrapper .block-header .block-subtitle {
            text-align: left;
        }
        
    }
    @media (max-width: 800px) {
        .home-block-wrapper {
            width: 320px;
        }
    }
    
</style>